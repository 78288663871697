// Scripts related to the campaign landing page template

(function( $ ) {
	
	let $header = $('.site-header-wrapper:not(.is-fixed)');
	let $cloned = $('.site-header-wrapper.is-fixed').clone();

	$cloned.addClass('-is-cloned -fixed-header-spacer');
	$('body').append($cloned);

	function setHeaderHeight() {
		$('body').css('--header-height', `${Math.floor($header.outerHeight(false))}px`);
		$('body').css('--header-fixed-height', `${Math.floor($cloned.outerHeight(false))}px`);
	}

	setHeaderHeight();
	$(window).resize(debounce(setHeaderHeight, 150));

	$(document).ready(() => {
		setHeaderHeight();
	});

})( jQuery );
