jQuery(function(){
	function changeFilter( val ) {
		let $form = jQuery('.js-filter-form');

		window.location = `${$form.attr('action')}?filter=${val}`;
	}

	// bind change event to select
	jQuery('#filter_by').on('change', function (event) {
		changeFilter(jQuery(this).val());
		event.preventDefault();
	    return false;
	});

	jQuery('.toggle-nav').click(function(e) {
			$menu = jQuery(this).closest('.site-header-wrapper').find('.menu.main');

			if ( !$menu.is(':visible') ) {
				bodyScrollLock.disableBodyScroll($menu[0], {reserveScrollBarGap: true});
			} else {
				bodyScrollLock.enableBodyScroll($menu[0]);
			}

	    $menu.slideToggle(500);

	    e.preventDefault();
	});

	if (jQuery(window).width() < 768) {
	  jQuery('.page_item_has_children a').each(function(){
	     jQuery(this).click(function(){
	        jQuery(this).click(function(){
	           
	             var href = jQuery(this).attr('href');
	              window.location.href = href;
	            
	        });
		  });
	  });
	}

	  // COPYRIGHT PICTUREES
	if (jQuery(window).width() > 1025) {
	  document.addEventListener("contextmenu", function(e){
		    e.preventDefault();
	 }, false);
	}

	if (jQuery(window).width() < 1026) {
	  	
	  jQuery('.menu-item-has-children').each(function(){
	  	var menu = jQuery(this);
	  		jQuery(this).click(function(){
	  			jQuery('.menu-item-has-children .sub-menu').slideUp();
	  			jQuery('.menu-item-has-children').removeClass('active');
	  			
	  				if(jQuery(this).find('.sub-menu').is(":hidden")){
	  					jQuery(this).find('.sub-menu').slideDown();
		  				jQuery(this).addClass('active');
	  				}else{
	  					jQuery(this).find('.sub-menu').slideUp();
		  				jQuery(this).removeClass('active');
	  				}
	  				
	  	    });
	  		
	  	});
	 
	  	jQuery('.menu-dropdown').click(function(){
	  		jQuery('.dropdown-menu').slideToggle();
	  		jQuery(this).toggleClass('active');
	  	});

	}
			
	// jQuery('.show-select').on('change',function(){
		// changeFilter(jQuery(this).val());
		// return false;		  		
	// });

	jQuery('.search-me').click(function(){
		jQuery('.search-overlay').slideDown();		
	});
	
	jQuery('.search-overlay .close').click(function(){
		jQuery('.search-overlay').slideUp();
	});

	// create styled select for desktop
	// jQuery('select#filter_by').each(function(){
	//     var jQuerythis = jQuery(this), numberOfOptions = jQuery(this).children('option').length;
	  
	//     jQuerythis.addClass('select-hidden'); 
	//     jQuerythis.wrap('<div class="select"></div>');
	//     jQuerythis.after('<div class="select-styled"></div>');

	//     var jQuerystyledSelect = jQuerythis.next('div.select-styled');
	//     jQuerystyledSelect.text(jQuerythis.children('option[selected]').last().text());
	  
	//     var jQuerylist = jQuery('<ul />', {
	//         'class': 'select-options'
	//     }).insertAfter(jQuerystyledSelect);
	  
	//     for (var i = 0; i < numberOfOptions; i++) {
	//         jQuery('<li />', {
	//             text: jQuerythis.children('option').eq(i).text(),
	//             rel: jQuerythis.children('option').eq(i).val()
	//         }).appendTo(jQuerylist);
	//     }
	  
	//     var jQuerylistItems = jQuerylist.children('li');
	  
	//     jQuerystyledSelect.click(function(e) {
	//         e.stopPropagation();
	//         jQuery('div.select-styled.active').not(this).each(function(){
	//             jQuery(this).removeClass('active').next('ul.select-options').hide();
	//         });
	//         jQuery(this).toggleClass('active').next('ul.select-options').toggle();
	//     });
	  
	//     jQuerylistItems.click(function(e) {
	//         e.stopPropagation();
	//         jQuerystyledSelect.text(jQuery(this).text()).removeClass('active');
	//         jQuerythis.val(jQuery(this).attr('rel'));
	//         jQuerylist.hide();

	//         // changeFilter(jQuery(this).attr('rel'));
	//     });
	  
	//     jQuery(document).click(function() {
	//         jQuerystyledSelect.removeClass('active');
	//         jQuerylist.hide();
	//     });

	// });
});
