// Initialise Slick slider

( function( $, window, document, undefined ) {

	// Uncomment to use SVG icon buttons
	const prevArrowHTML = `<button type="button" class="slick-prev" aria-label="Previous slide"><svg xmlns="http://www.w3.org/2000/svg" width="60.2" height="20.514" viewBox="0 0 60.2 20.514"><path d="M433.2,421.529v6.752h6.788V462.12H433.2v6.752h20.514V462.12h-6.789V428.281h6.789v-6.752l-10.323-12.857Z" transform="translate(-408.673 453.716) rotate(-90)" fill="currentcolor"/></svg></button>`;
	const nextArrowHTML = `<button type="button" class="slick-next" aria-label="Next slide"><svg xmlns="http://www.w3.org/2000/svg" width="60.2" height="20.514" viewBox="0 0 60.2 20.514"><path d="M433.2,421.529v6.752h6.788V462.12H433.2v6.752h20.514V462.12h-6.789V428.281h6.789v-6.752l-10.323-12.857Z" transform="translate(468.873 -433.202) rotate(90)" fill="currentcolor"/></svg></button>`;


	const $image_carousel = $('.js-image-carousel');
	
		if ( $image_carousel.length ) {

			// Run on a specific selector with default args
			$image_carousel.slick( {
				autoplay: false,
				dots: false,
				arrows: true,
				prevArrow: prevArrowHTML,
				nextArrow: nextArrowHTML,
				infinite: true,
				adaptiveHeight: false,
				draggable: true,
				centerMode: true,
				centerPadding: '19.05%',
			} );
			
		}

		// disable slick for plans on mobile

		const $test_carousel = $('.testimonial-carousel');
	
		if ( $test_carousel.length ) {

			// Run on a specific selector with default args
			$test_carousel.slick( {
				autoplay: true,
				dots: true,
				arrows: false,
				infinite: true,
				adaptiveHeight: false,
				draggable: true,
				centerMode: false,
				
			} );
			
		}

		

	if (jQuery(window).width() > 768) {
		const $plans_carousel = $('.js-plans-carousel');
		const $plans_carousel_two = $('.js-plans-carousel-two');

		if ( $plans_carousel.length ) {

			// Run on a specific selector with default args
			$plans_carousel.slick( {
				autoplay: false,
				dots: false,
				arrows: true,
				prevArrow: prevArrowHTML,
				nextArrow: nextArrowHTML,
				infinite: true,
				adaptiveHeight: true,
				draggable: false,
			} );
			
		}
		if ( $plans_carousel_two.length ) {

			// Run on a specific selector with default args
			$plans_carousel_two.slick( {
				autoplay: false,
				dots: false,
				arrows: true,
				prevArrow: prevArrowHTML,
				nextArrow: nextArrowHTML,
				infinite: true,
				adaptiveHeight: true,
				draggable: false,
			} );
			
		}
	}
	
} )( jQuery, window, document );
