// Setup the Featherlight plugin

( function( $, window, document, undefined ) {

	/**
	 * Get elements matching the selector from the current Featherlight instance.	
	 * @param {String} selector A jQuery compatible selector.
	 * @return {jQuery} A jQuery element or set of elements.
	 */
	function getFromCurrent( selector ) {
		const current = $.featherlight.current();

		if ( current ) {
			const $elements = current.$instance.find( selector );

			if ( $elements.length ) {
				return $elements;
			}
		}

		// If here, nothing was found or no current instance exists
		return false;
	}

	function lazyload_content( $container ) {
		let $lazyload = $container.find('[data-lazy]');

		if ( $lazyload.length ) {
			let $lazyload_replace = $($lazyload.data('lazy'));

			$lazyload_replace.imagesLoaded().progress( function(instance, image) {
				if ( image.isLoaded ) {
					$(image.img).removeClass('_not-loaded');
				}
			} );
			$lazyload.replaceWith( $lazyload_replace );
		}
	}

	function downloadUrl(url) {
		const link = document.createElement("a");
		link.style.display = "none";
		link.href = url;
		link.download = url.split('/').reverse()[0];

		// It needs to be added to the DOM so it can be clicked
		document.body.appendChild(link);
		link.click();

		// To make this work on Firefox we need to wait
		// a little while before removing it.
		setTimeout(() => {
			URL.revokeObjectURL(link.href);
			link.parentNode.removeChild(link);
		}, 0);
	}

    function addQueryArgs(params, url) {
        const queryString = Object.keys(params)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&');
        const separator = url.includes('?') ? '&' : '?';
        return url + separator + queryString;
    }

	if ( typeof $.featherlight !== 'undefined' ) {	
		// Change default featherlight selector, favour gallery use case
		$.featherlight.defaults.closeIcon = '<svg class="svg-icon svg-icon-close" viewBox="0 0 24 24" aria-hidden="true" focusable="false"><path fill="#fff" d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"></path></svg>';

		// Don't allow the body to scroll while a container is open
		$.featherlight.defaults.afterOpen = (event) => {
			console.log( 'afterOpen' );
			$('body').addClass('featherlight-open');

			//ScrollVelocity.disable();
			ViewportScroll.disable();
		};

		$.featherlight.defaults.afterContent = function (event) {
			console.log( 'afterContent' );
			// Check for lazy loading of content and populate if needed
			let $thumbnail = this.$currentTarget;
			let $thumbnail_lightbox = $thumbnail.next();
			let $content = this.$content;

			lazyload_content( $content );
			lazyload_content( $thumbnail_lightbox );


			// Autoplay videos in lightbox
			let $iframe = getFromCurrent('iframe');

			if ( $iframe.length ) {
				$iframe.get(0).src = addQueryArgs( {
					autoplay: 1,
					rel: 0,
					autopause: 1
				}, $iframe.get(0).src );
			}

			let $downloadBtn = getFromCurrent('button[data-download]');

			if ( $downloadBtn.length ) {
				$downloadBtn.click(function(event) {
					downloadUrl( $(this).attr('data-download') );
				});
			}
		};

		$.featherlight.defaults.beforeClose = (event) => {
			console.log( 'beforeClose' );
			ViewportScroll.enable();
			//ScrollVelocity.enable();

			$('body').removeClass('featherlight-open');
		};

	}

} )( jQuery, window, document );
