/**
 * Open and close submenus in a11y friendly way.
 *
 * Should be able to `tab` across all top level menu items.
 * 		Does not open the submenu when a top level item is focused.
 *
 * Pressing `enter` should expand the submenu *but* not move focus into it.
 * 1)	Submenu state is controlled by aria-expanded attr on 
 *   	top level item ensuring a11y attrs must be set to use it.
 *   	Focus does not move.
 *
 * Pressing `tab` once open should move to first submenu item.
 * 		We get this "for free" due to our submenu links 
 * 		directly following the top level link in the DOM structure.
 *
 * If `Esc` is pressed while in the submenu, it should collapse and
 * focus returns to the top level item.
 * 		See 2)
 *
 * If `tab` is pressed on the last submenu item, submenu should close 
 * and focus transferred to the next top level item.
 * 		3) Checks for focus on the next tabbable element to detect this.
 *
 * If `Shift` + `tab` is pressed on the first submenu item, 
 * focus moves naturally to the top level item.
 * If pressed again, submenu should close and focus moves 
 * to previous top level item/previous tabbable element
 * 		4) Checks for focus on the previous tabbable element to detect this.
 */

( function( $, window, document, undefined ) {
	
	const IS_MOBILE_NAV_MQ = '(max-width: 1px)'; // TODO
	const MENU_OPEN_ANIMATION = {
		duration: 300,
		easing: 'easeOutCubic'
	};
	const MENU_CLOSE_ANIMATION = {
		duration: 200,
		easing: 'easeOutCubic'
	};

	let $mainNav = $('.js-site-nav');
	let $triggers = $('.js-has-submenu');


	// init a11y attrs
	$triggers.each((index, trigger) => {
		$(trigger).attr('aria-expanded', 'false');
		$(trigger).next().attr('aria-hidden', 'true');
	});


	function open( $trigger ) {
		bindCloseHandlers($trigger);

		bodyScrollLock.disableBodyScroll(null, {reserveScrollBarGap: true});

		// 1)
		$trigger.attr('aria-expanded', 'true');
		$('body').addClass('submenu-open');
		$trigger.next().stop().slideDown(MENU_OPEN_ANIMATION);
		$trigger.next().attr('aria-hidden', 'false');
	}

	function close( $trigger, options = {} ) {
		// 1)
		$trigger.attr('aria-expanded', 'false');
		if ( !options.preserveBodyClass ) {
			$('body').removeClass('submenu-open');
		}
		$trigger.next().stop().slideUp(MENU_CLOSE_ANIMATION);
		$trigger.next().attr('aria-hidden', 'true');

		bodyScrollLock.enableBodyScroll(null);

		unbindCloseHandlers($trigger);
	}

	function toggle( $trigger ) {
		if ( 'false' === $trigger.attr('aria-expanded') ) {
			open($trigger);
		} else {
			close($trigger);
		}
	}

	function closeCurrent( $interactingWith = null ) {
		let $current = $triggers.filter('[aria-expanded="true"]');
		if ( $current.length && (null !== $interactingWith && !$current.is($interactingWith)) ) {
			close($current, {preserveBodyClass: true, fromEvent:"closeCurrent"});
		}
	}


	/**
	 * MOUSE & KEYBOARD SUPPORT
	 */
	$triggers.click((event) => {
		if ( window.matchMedia(IS_MOBILE_NAV_MQ).matches ) {
			return;
		}

		let $trigger = $(event.target);
		if ( !$trigger.is('.js-has-submenu') ) {
			$trigger = $trigger.closest('.js-has-submenu');
		}

		closeCurrent($trigger);
		toggle($trigger);

		event.preventDefault();
		return false;
	});

	// set flags so we can differentiate clicks from keyboard focus events
	$triggers.on('mousedown', function(event) {
		this.mouseDown = true;
	});

	$triggers.on('mouseup', function(event) {
		this.mouseDown = false;
	});

	/**
	 * TOUCH SUPPORT
	 * 5) Tapping on an item will open the submenu associated with it.
	 * 6) Tapping on any other item will close the active submenu.
	 * 7) Tapping outside of the submenu will close it.
	 * 8) Zoom, or other multi-touch gestures should not open or close the menu.
	 */
	$triggers.on( 'touchend', function(event) {
		if ( window.matchMedia(IS_MOBILE_NAV_MQ).matches ) {
			return;
		}
		
		// 8)
		if ( event.touches.length > 1 ) {
			return;
		}

		let $trigger = $(event.target);
		if ( !$trigger.is('.js-has-submenu') ) {
			$trigger = $trigger.closest('.js-has-submenu');
		}

		closeCurrent($trigger);
		toggle($trigger);

		event.preventDefault();
		return false;
	} );


	function bindCloseHandlers( $trigger ) {
		let $prev_tab = getPrevTabbable($trigger);
		let $next_tab = getNextTabbable($trigger);

		/**
		 * KEYBOARD SUPPORT
		 */

		// 2)
		function escHandler(event) {
			if ( 'Escape' !== event.key ) {
				return;
			}

			// Move focus back to trigger if inside the submenu
			if ( $trigger.next().is( $(document.activeElement).closest('.submenu') ) ) {
				focusOnElement($trigger);
			}

			close($trigger);
		}

		// TODO: Fires when clicking between adjacent menu items causing body class to be removed
		function blurHandler(event) {
			let options = {};

			// preserve class if toggling between menu items
			if ( event.target.mouseDown ) {
				options.preserveBodyClass = true;
			}

			close($trigger, options);
		}

		/**
		 * TOUCH SUPPORT
		 */
		function touchHandler(event) {
			// 8)
			if ( event.touches.length > 1 ) {
				return;
			}

			let $closest_submenu = $(event.target).closest('.submenu');
			if ( $closest_submenu.length ) {
				return;
			}

			// 7)
			closeCurrent();
		}

		$(document).bind('keydown.submenu', escHandler);
		// Menu has been blurred if either of these get focus
		$prev_tab.bind('focus.submenu', blurHandler); // 4)
		$next_tab.bind('focus.submenu', blurHandler); // 3)

		$(document).bind('touchend.submenu', touchHandler);
	}

	function unbindCloseHandlers( $trigger ) {
		let $prev_tab = getPrevTabbable($trigger);
		let $next_tab = getNextTabbable($trigger);

		$(document).off('.submenu');
		$prev_tab.off('.submenu');
		$next_tab.off('.submenu'); 
	}

} )( jQuery, window, document );
