// Lazyload iframe contents

(function( $ ) {
	
	let $frames = $('[data-lazy-frame]');

	$frames.on('click', function(event) {
		let $frame = $($(this).attr('data-lazy-frame'));
		$frame.addClass('rounded-corners');

		$(this).replaceWith($frame);

		event.preventDefault();
		return false;
	});

})( jQuery );
