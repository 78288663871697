// sticky items to top when position:sticky cannot be used

(function( $ ) {

	let $stickies = $('.js-sticky');
	let $mailtouiModal = $('#mailtoui-modal');

	$stickies.each((index, node) => {
		let $node = $(node);
		let nodeTop = $node.offset().top;
		// create cloned version of node to fix to top
		// prevents content jump when scrolling
		let $nodeFixed = $node.clone().addClass('is-fixed').hide();

		if ( $node.hasClass('js-sticky--after-header') ) {
			nodeTop = $node.offset().top - $('.site-header-wrapper.is-fixed').outerHeight(false);
			$nodeFixed.css('top', 'var(--header-fixed-height)');
		}

		function shouldShowFixed() {
			let scrollTop = $(window).scrollTop();

			return scrollTop >= nodeTop;
		}

		// remove fixed clone from a11y tree
		$nodeFixed.attr('aria-hidden', 'true');
		$nodeFixed.attr('role', 'presentation');
		$nodeFixed.find('a, button').attr('tabindex', '-1');

		$node.after($nodeFixed);
		$nodeFixed.toggle(shouldShowFixed());

		$(window).resize(debounce(function(event) {
			if ( $node.hasClass('js-sticky--after-header') ) {
				nodeTop = $node.offset().top - $('.site-header-wrapper.is-fixed').outerHeight(false);
				$nodeFixed.css('top', 'var(--header-fixed-height)');
			}

			nodeTop = $node.offset().top;

			$nodeFixed.toggle(shouldShowFixed());
		}, 150));


		function onScroll(event) {
			if ( $node.hasClass('js-sticky--after-header') ) {
				nodeTop = $node.offset().top - $('.site-header-wrapper.is-fixed').outerHeight(false);
				$nodeFixed.css('top', 'var(--header-fixed-height)');
			}

			$nodeFixed.toggle(shouldShowFixed());

			if ( $(window).scrollTop() > 60 ) {
				$('body').addClass('is-scrolled');
			} else {
				$('body').removeClass('is-scrolled');
			}
		}

		setTimeout(function() {
		  onScroll(); // run for initial load
		}, 350);
		$(window).on('scroll.sticky-el', throttle(onScroll, 50));

		// Temporarily disable sticky updating when modal opens
		$mailtouiModal.on('open', function() {
			$(window).off('scroll.sticky-el');
		});

		$mailtouiModal.on('close', function() {
			$(window).on('scroll.sticky-el', throttle(onScroll, 50));
			// call to ensure update
			onScroll();
		});
	});
	
})( jQuery );
