(function( $ ) {

	function getFixedOffset() {
		const $toc = $('.sp-toc-wrapper');
		const $header = $('.site-header-wrapper.is-fixed');

		let offset = 50;

		if ( $header.length ) {
			offset += $header.outerHeight(false);
		}

		if ( $toc.length ) {
			offset += $toc.outerHeight(false);
		}

		return offset;
	}
	
	$(document).on('click', 'a[href*="#"]', function(event) {
		// Remove links that don't actually link to anything
		if ( this.href === '#' || this.href === '#0' ) {
			return;
		}

		// don't smooth scroll if element explicitly says no
		if ( this.hasAttribute('data-noanchor') ) {
			return;
		}

	    // On-page links
	    if (
	      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
	      location.hostname == this.hostname
	    ) {
	      // Figure out element to scroll to
	      var target = $(this.hash);
	      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
	      // Does a scroll target exist?
	      if (target.length) {
	        // Only prevent default if animation is actually gonna happen
	        event.preventDefault();
	        $('html, body').animate({
	          scrollTop: target.offset().top - getFixedOffset()
	        }, 300);
	      }
	    }
	});

})( jQuery );
