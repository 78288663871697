// Initialise TwentyTwenty comparison slider

( function( $, window, document, undefined ) {

$(window).on("load", function() {
  $comparisons = $('.js-image-comparison');

  if ( !$comparisons.length ) {
    return;
  }

  $comparisons.each((index, node) => {
    let $comparison = $(node);

    $comparison.twentytwenty({
      before_label: $comparison.attr('data-before-caption'),
      after_label: $comparison.attr('data-after-caption'),
    });
  });

});

} )( jQuery, window, document );
