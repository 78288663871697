// Proxy click event for links within cards/boxes
// Makes the whole card/box clickable even though link is actually inside the hx tag for semantics and accessibility

(function( $ ) {

	let $proxies = $('.js-proxy-click');

	$proxies.each((index, proxy) => {
		let down, up;
		let $link = $(proxy).find('a');

		$(proxy).on('mousedown', function(event) {
			down = +new Date();
		});

		$(proxy).on('mouseup', function(event) {
			up = +new Date();
	        if ((up - down) < 200) {
	            $link[0].click();
	        }
		});

		// add affordance
		$(proxy).css('cursor', 'pointer');
	});
	
})( jQuery );
